.e404 {
    display: flex;
    min-height: 90vh;
    background-image: url('../img/background-404.jpg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    justify-content: center;
    align-items: center;

    &__box {
        display: inline-block;
        padding: 30px;
        border: 2px solid #fff;
        background-color: rgba(#fff, 0.75);
    }

    &__title {
        margin-bottom: 15px;
        font-family: $first-font;

        @include rfs(42px);

        span {
            color: $first-color;
        }
    }

    &__text {
        margin-bottom: 15px;
        font-family: $second-font;

        @include rfs(24px);
    }
}
