// Colors
$first-color-dark: #4ec04e;
$first-color: #74d774;
$first-color-light: #a0e8a0;

$second-color: #f6f6f6;

$third-color-dark: #17222c;
$third-color: #22292f;
$third-color-light: #5a6772;

// Fonts
$first-font: 'Caveat', cursive;
$second-font: 'Ubuntu', sans-serif;
$logo-font: 'Sacramento', cursive;
