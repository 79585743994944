.button {
    display: inline-block;
    padding: 10px 15px;
    border: 1px solid $third-color;
    border-radius: 2px;
    color: $third-color;
    background-color: transparent;
    font-family: $second-font;
    font-weight: 400;
    transition: color 0.25s, border-color 0.5s;

    @include rfs(16px);

    &:hover,
    &:active,
    &:focus {
        color: $first-color-dark;
        border-color: $first-color-dark;
        text-decoration: none;
        outline: none;
    }

    &.is-success {
        color: $first-color;
        border-color: $first-color-dark;
        background-color: $first-color-dark;
    }

    &.is-error {
        color: $third-color;
        border-color: #e51c22;
        background-color: #e51c22;
    }
}
