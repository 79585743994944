.home-florist-post {
    padding: 120px 0;
    background: $second-color;

    @include media-breakpoint-down (md) {
        padding: 90px 0;
    }

    &__logo-pocztakwiatowa {
        margin-bottom: 50px;
        height: auto;
        max-width: 100%;
    }

    &__text {
        margin-bottom: 0;
        font-family: $second-font;
        font-weight: 300;
        text-align: center;
        line-height: 1.75;

        @include rfs(22px);

        @include media-breakpoint-down (md) {
            @include rfs(18px);
        }
    }
}
