.cookie-info {
    position: fixed;
    right: 15px;
    bottom: 15px;
    height: auto;
    width: 700px;
    padding: 30px;
    border-radius: 3px;
    background-color: rgba(255, 255, 255, 0.8);
    opacity: 0;
    z-index: 1000;
    transition: opacity 0.25s;
    pointer-events: none;

    @include media-breakpoint-down (md) {
        right: 0;
        bottom: 0;
        width: 100%;
    }

    &.is-visible {
        opacity: 1;
        pointer-events: all;
    }

    &__icon-cookie {
        &::before {
            color: $third-color;

            @include rfs(48px);
        }
    }

    &__text {
        margin-right: 5px;
        margin-bottom: 0;
        color: $third-color;
        font-family: $second-font;

        @include rfs(16px);
    }

    &__close {
        position: absolute;
        top: 15px;
        right: 15px;

        &:hover {
            text-decoration: none;
        }
    }

    &__icon-close {
        &::before {
            color: $third-color;

            @include rfs(16px);
        }
    }
}
