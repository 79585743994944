.footer {
    padding: 25px 0;
    background-color: $second-color;

    &__text {
        margin-bottom: 0;
        color: $third-color;
        font-family: $second-font;
        font-weight: 300;

        @include rfs(18px);

        &--heart {
            color: $first-color;
        }
    }

    &__link {
        color: $third-color;
        transition: color 0.25s;

        &:hover {
            color: $first-color;
            text-decoration: none;
        }
    }
}
