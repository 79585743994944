@font-face {
	font-family: "iconfont";
	src: url('../iconfont/iconfont.eot');
	src: url('../iconfont/iconfont.eot?#iefix') format('eot'),
		url('../iconfont/iconfont.woff2') format('woff2'),
		url('../iconfont/iconfont.woff') format('woff'),
		url('../iconfont/iconfont.ttf') format('truetype'),
		url('../iconfont/iconfont.svg#iconfont') format('svg');
}

@mixin icon-styles {
	font-family: "iconfont";
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	font-style: normal;
	font-variant: normal;
	font-weight: normal;
	// speak: none; // only necessary if not using the private unicode range (firstGlyph option)
	text-decoration: none;
    text-transform: none;
    line-height: 1;
}

%icon {
	@include icon-styles;
}

@function icon-char($filename) {
	$char: "";

	@if $filename == address {
		$char: "\E001";
	}
	@if $filename == at {
		$char: "\E002";
	}
	@if $filename == clock {
		$char: "\E003";
	}
	@if $filename == close {
		$char: "\E004";
	}
	@if $filename == cookie {
		$char: "\E005";
	}
	@if $filename == external-link {
		$char: "\E006";
	}
	@if $filename == facebook {
		$char: "\E007";
	}
	@if $filename == loading {
		$char: "\E008";
	}
	@if $filename == message {
		$char: "\E009";
	}
	@if $filename == mobile-phone {
		$char: "\E00A";
	}
	@if $filename == phone {
		$char: "\E00B";
	}
	@if $filename == piggy-bank {
		$char: "\E00C";
	}

	@return $char;
}

@mixin icon($filename, $insert: before, $extend: true) {
	&:#{$insert} {
		@if $extend {
			@extend %icon;
		} @else {
			@include icon-styles;
		}
		content: icon-char($filename);
	}
}

.icon-address {
	@include icon(address);
}
.icon-at {
	@include icon(at);
}
.icon-clock {
	@include icon(clock);
}
.icon-close {
	@include icon(close);
}
.icon-cookie {
	@include icon(cookie);
}
.icon-external-link {
	@include icon(external-link);
}
.icon-facebook {
	@include icon(facebook);
}
.icon-loading {
	@include icon(loading);
}
.icon-message {
	@include icon(message);
}
.icon-mobile-phone {
	@include icon(mobile-phone);
}
.icon-phone {
	@include icon(phone);
}
.icon-piggy-bank {
	@include icon(piggy-bank);
}
