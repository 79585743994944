.home-welcome {
    position: relative;
    height: 100vh;
    overflow: hidden;

    @include media-breakpoint-down (sm) {
        height: initial;
    }

    &__background {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        filter: blur(5px);

        @include media-breakpoint-down (sm) {
            height: 100%;
            filter: blur(1px);
        }
    }

    &__content-box {
        position: relative;
        padding: 65px 30px 60px 30px;
        background-color: rgba(255, 255, 255, 0.25);
        text-align: center;

        @include media-breakpoint-down (sm) {
            padding: 90px 30px;
        }
    }

    &__title {
        margin-bottom: 45px;
        color: $third-color;
        font-family: $logo-font;
        line-height: 1;
        opacity: 0.95;

        @include rfs(72px);
    }

    &__text {
        margin-bottom: 0;
        max-width: 70vw; // IE Fix <3
        color: $third-color;
        font-family: $first-font;
        font-weight: 400;
        opacity: 0.95;

        @include rfs(26px);

        @include media-breakpoint-down (sm) {
            max-width: initial;
        }

        span {
            color: $first-color-dark;
        }
    }
}
