.swal2-styled.swal2-confirm {
    border: 2px solid transparent;
    border-radius: 2px;
    color: #fff;
    background-color: $first-color;
    font-family: $second-font;
    font-weight: 700;
    transition:
        background-color 0.25s,
        border-color 0.25s;

    &:hover,
    &:active {
        color: #fff;
        background-color: $first-color-light;
        text-decoration: none;
    }

    &:active,
    &:focus {
        box-shadow: none;
    }
}
