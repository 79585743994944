// Bootstrap configuration
$grid-columns: 24;
$grid-gutter-width: 30px;
$modal-backdrop-bg: #000;

$grid-breakpoints: (
    tn: 0,      // Mobile Extra Small
    xs: 576px,  // Mobile
    sm: 768px,  // Tablet
    md: 1024px, // Laptop 1366 x 768
    lg: 1280px,
    xl: 1600px
);

$container-max-widths: (
    xs: 540px,
    sm: 720px,
    md: 960px,
    lg: 1210px,
    xl: 1410px
);

// Load bootstrap
@import '../../../../node_modules/bootstrap/scss/bootstrap';
