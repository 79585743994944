.home-offer {
    padding: 120px 0;
    background: $second-color;

    @include media-breakpoint-down (md) {
        padding: 90px 0;
    }

    &__title {
        margin-bottom: 50px;
        font-family: $first-font;
        font-weight: 400;
        text-align: center;

        @include rfs(48px);

        @include media-breakpoint-down (md) {
            @include rfs(54px);
        }

        span {
            color: $first-color;
        }
    }

    &__text {
        margin-bottom: 50px;
        font-family: $second-font;
        font-weight: 300;
        text-align: center;
        line-height: 1.75;

        @include rfs(22px);

        @include media-breakpoint-down (md) {
            @include rfs(18px);
        }
    }

    &__item-box {
        margin-bottom: 30px;
    }

    &__item {
        display: flex;
        height: 100%;
        padding: 30px;
        border: 2px solid #fff;
        border-radius: 2px;
        background: rgba(255, 255, 255, 0.5);
        background: linear-gradient(0deg, rgba(189, 255, 177, 0.25) 0%, rgba(225, 255, 218, 0.5) 100%);
        line-height: 1.5;
        flex-direction: column;
    }

    &__item-title {
        position: relative;
        margin-bottom: 60px;
        text-align: center;
        font-family: $first-font;
        font-weight: 400;

        @include rfs(36px);

        @include media-breakpoint-down (md) {
            @include rfs(46px);
        }

        span {
            color: $first-color;
        }

        &::after {
            content: '';
            position: absolute;
            left: 50px;
            bottom: -30px;
            height: 2px;
            width: calc(100% - 100px);
            background-color: $first-color;
            opacity: 0.5;
        }
    }

    &__item-text {
        margin-bottom: 0;
        font-family: $second-font;
        font-weight: 300;
        line-height: 1.6;
        opacity: 0.9;

        @include rfs(18px);
    }
}
