@import 'mixins';

/**
 * Czcionki
 * @include font-face({font-name}, '../fonts/{font-folder}/{font-file-name}', {font-weight}, {font-style}, {font-type: woff ttf woff2});
 */

// Sacramento
@include font-face('Sacramento', '../fonts/Sacramento/Sacramento', 400, normal, ttf);

// Caveat
@include font-face('Caveat', '../fonts/Caveat/Caveat-Regular', 400, normal, ttf);
@include font-face('Caveat', '../fonts/Caveat/Caveat-Bold', 700, normal, ttf);

// Ubuntu
@include font-face('Ubuntu', '../fonts/Ubuntu/Ubuntu-Light', 300, normal, ttf);
@include font-face('Ubuntu', '../fonts/Ubuntu/Ubuntu-Regular', 400, normal, ttf);
@include font-face('Ubuntu', '../fonts/Ubuntu/Ubuntu-Medium', 500, normal, ttf);
@include font-face('Ubuntu', '../fonts/Ubuntu/Ubuntu-Bold', 700, normal, ttf);
