.preloader {
    display: flex;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: $second-color;
    transition: all 0.5s;
    opacity: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 9999;

    &--hide {
        pointer-events: none;
        opacity: 0;
    }

    &__icon-loading {
        display: block;
        margin-bottom: 50px;

        &::before {
            color: $first-color;
            display: inline-block;
            animation-name: spin;
            animation-duration: 1s;
            animation-iteration-count: infinite;
            animation-timing-function: linear;

            @include rfs(72px);
        }

        @keyframes spin {
            from {
                transform: rotate(0deg);
            }

            to {
                transform: rotate(360deg);
            }
        }
    }

    &__title {
        color: $third-color;
        font-family: $second-font;
        font-weight: 300;
        text-align: center;
        letter-spacing: 5px;

        span {
            color: $first-color;
            letter-spacing: 8px;
        }

        @include rfs(48px);
    }
}
