.home-contact {
    padding: 120px 0;
    background: $second-color;

    @include media-breakpoint-down (sm) {
        padding: 90px 0;
    }

    &__row {
        &--contact-form {
            margin-top: 50px;
        }
    }

    &__title {
        margin-bottom: 50px;
        font-family: $first-font;
        font-weight: 400;
        text-align: center;

        @include rfs(48px);

        @include media-breakpoint-down (sm) {
            @include rfs(54px);
        }

        span {
            color: $first-color;
        }
    }

    &__text {
        margin-bottom: 50px;
        font-family: $second-font;
        font-weight: 300;
        text-align: center;
        line-height: 1.75;

        @include rfs(22px);

        @include media-breakpoint-down (sm) {
            @include rfs(18px);
        }

        &--smaller {
            margin-bottom: 15px;
            text-align: left;
            line-height: 1.5;

            @include rfs(18px);

            @include media-breakpoint-down (sm) {
                text-align: center;
            }
        }
    }

    &__item {
        margin-bottom: 15px;

        @include media-breakpoint-down (sm) {
            text-align: center;
        }
    }

    &__item-title {
        margin-bottom: 5px;
        font-family: $second-font;
        font-weight: 400;

        @include rfs(18px);

        span {
            color: $first-color-dark;
        }
    }

    &__item-title-icon {
        color: $third-color !important;
        margin-right: 5px;
    }

    &__item-title-asterix {
        color: #e51c22;
    }

    &__link {
        color: $third-color;
        transition: color 0.25s;

        &:hover {
            color: $first-color;
            text-decoration: none;
        }
    }

    &__link-icon {
        font-size: 10px;
        vertical-align: text-bottom;
    }

    &__table {
        display: inline-block;

        th {
            padding-right: 10px;
        }

        th,
        td {
            font-family: $second-font;
            font-weight: 300;
            line-height: 1.5;

            @include rfs(18px);
        }
    }

    &__alert {
        margin-bottom: 30px;
        border: 1px solid $first-color-dark;
        background-color: rgba($first-color-light, 0.1);
        color: $first-color-dark;
    }

    &__input {
        border: 1px solid $third-color-light;
        border-radius: 2px;

        &:focus,
        &:active {
            box-shadow: none;
            border-color: $first-color;
        }

        &--phone-prefix {
            flex: none !important;
            width: 65px !important;
        }

        &--textarea {
            resize: none;
        }

        &--hnypot {
            position: absolute;
            top: 0;
            left: 0;
            height: 0;
            width: 0;
            opacity: 0;
            z-index: -1;
        }
    }

    &__map-box {
        position: relative;
        height: 350px;
    }

    &__map {
        height: 350px;
        width: 100%;
    }
}
