.header {
    $root: &;

    &__navbar {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        padding: 15px;
        z-index: 999;
        transition:
            opacity 0.25s,
            transform 0.5s;

        @include media-breakpoint-down (xs) {
            background-color: #fff;
        }

        &.hide {
            @include media-breakpoint-down (xs) {
                transform: translateY(-100%);
            }
        }

        &.is-scrolling {
            #{$root}__navbar-nav {
                box-shadow: 0 0 20px -10px rgba(0, 0, 0, 0.15);

                @include media-breakpoint-down (xs) {
                    box-shadow: none;
                }

                &::before,
                &::after {
                    opacity: 1;
                }
            }

            #{$root}__navbar-nav-link {
                color: $third-color;
                background-color: rgba(255, 255, 255, 0.85);

                &.active,
                &:hover {
                    color: $first-color;
                    background-color: rgba(255, 255, 255, 0.99) !important;
                }
            }
        }
    }

    &__navbar-container {
        display: block !important;
    }

    &__navbar-toggler {
        position: relative;
        height: 30px;
        width: 35px;
        padding: 0;
        border: 0;

        &:active,
        &:focus {
            outline: none;
        }

        span {
            position: absolute;
            left: 0;
            height: 2px;
            width: 100%;
            border-radius: 2px;
            background: $third-color;
            transition: 0.2s;

            &:nth-child(1) {
                top: 8px;
                transform: rotate(45deg) translate(8px, 7px);
            }

            &:nth-child(2) {
                top: 18px;
                opacity: 0;
            }

            &:nth-child(3) {
                top: 28px;
                transform: rotate(-45deg) translate(7px, -7px);
            }
        }

        &.collapsed {
            span {
                &:nth-child(1) {
                    transform: unset;
                }

                &:nth-child(2) {
                    opacity: 1;
                }

                &:nth-child(3) {
                    transform: unset;
                }
            }
        }
    }

    &__navbar-nav {
        position: relative;
        width: 100%;
        border-top: 1px solid #fff;
        border-bottom: 1px solid #fff;
        background-color: rgba(255, 255, 255, 0.25);

        &::before,
        &::after {
            content: '';
            position: absolute;
            top: -1px;
            height: calc(100% + 2px);
            width: 100vw;
            border-top: 1px solid #fff;
            border-bottom: 1px solid #fff;
            background-color: rgba(255, 255, 255, 0.85);
            opacity: 0;
            transition: opacity 0.25s;

            @include media-breakpoint-down (xs) {
                display: none;
            }
        }

        &::before {
            left: -100vw;
        }

        &::after {
            right: -100vw;
        }
    }

    &__navbar-nav-link {
        padding: 15px;
        border-radius: 0 !important;
        color: #fff;
        font-family: $second-font;
        font-weight: 500;
        text-transform: uppercase;
        transition: color 0.25s, background-color 0.25s;

        &.active,
        &:hover {
            color: #fff;
            background-color: rgba(255, 255, 255, 0.2) !important;
        }

        @include media-breakpoint-down (xs) {
            color: $third-color;
            width: 100%;

            &.active {
                color: $first-color !important;
            }
        }
    }
}
