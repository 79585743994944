.e404 {
    &__title {
        font-family: $first-font;
        font-weight: 900;
        text-align: center;

        @include rfs(120px);

        @include media-breakpoint-down (md) {
            @include rfs(48px);
        }
    }

    &__text {
        font-family: $first-font;
        font-weight: 900;
        text-align: center;

        @include rfs(36px);
    }
}
