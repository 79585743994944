* {
    box-sizing: border-box;

    &:focus,
    &:active,
    &:hover {
        outline: none;
    }
}

html,
body {
    -webkit-overflow-scrolling: touch;
}

html {
    font-size: 100%;
}

body {
    background-color: #fff;
}

p {
    font-family: $second-font;
}

.icon {
    vertical-align: middle;
}

.btn {
    &:focus,
    &:active {
        box-shadow: none;
    }
}
